/*-----buttons----*/
.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	border-radius: 0.25rem;
	border: 1px solid transparent;
	box-sizing: border-box;
	font-size: 0.875rem;
	padding: 0.5rem 1rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 100%; /* 0.5rem */
	text-transform: uppercase;
	min-height: 1.5rem;
	cursor: pointer;

	&-global {
		min-height: 2.75rem;
		border-radius: 0.75rem;
		background: var(--color_11);
		box-shadow: 0 0.625rem 1.875rem 0 rgba(0, 0, 0, 0.08);
		color: var(--color_0);
		line-height: 1.5;
		letter-spacing: 0.012px;
		text-transform: none;
		font-size: 1rem;
		font-family: "Inter-Bold";

		&:hover {
			color: var(--color_0);
			background: var(--color_2);
			border-color: var(--color_2);
		}
		&:active,
		&:focus {
			color: var(--color_0);
			background: var(--color_2);
			border-color: var(--color_2);
			box-shadow: none;
		}
		&:disabled,
		&.disabled {
			color: var(--color_0);
			background: var(--color_1);
			opacity: 0.1;
			cursor: not-allowed;
		}
	}
	&-white {
		background: var(--color_0);
		color: var(--color_1);

		span {
			background: var(--button, linear-gradient(264deg, var(--color_2) 3%, var(--color_1) 95.71%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&:hover {
			transform: scale(1.1);
		}
	}
	&-border {
		border-radius: 0.1875rem;
		border: 1px solid var(--color_1);

		color: var(--color_4);
		&:hover,
		&:active,
		&:focus {
			color: var(--color_2);
			border-color: var(--color_2);
			background: var(--color_0);
		}
	}
	&-gradient {
		border-radius: 0.1875rem;
		background: linear-gradient(264deg, var(--color_12) 3%, var(--color_11) 95.71%);
		border-color: var(--color_12);
		color: var(--color_0);
		transition: 0.3s;
		gap: 0.5rem;
		text-align: center;
		&:hover {
			color: var(--color_0);
			border-color: var(--color_12);
			background: linear-gradient(264deg, var(--color_12) 3%, var(--color_11) 95.71%);
		}
	}
}
