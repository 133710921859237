//реклама в сайдбаре
.widget-img-bg {
	width: 100%;
	max-width: 18.75rem;
	height: auto;
	display: block;
	position: relative;
	background-size: cover;
	background-position: 50% 50%;

	@media (max-width: 992px) {
		width: 50%;
	}
	@media (max-width: 480px) {
		width: 100%;
		max-width: 25rem;
	}

}
//реклама в шапке

.baner-head {
	width: 100%;
	transition: 0.6s;
	display: block;
	margin: 0;
	position: relative;
	z-index: 20;
}
//реклама в контенте
.baner-content{
	width: 100%;
	margin: 1.5rem 0;
}
//реклама в фоне
.baner-full{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	background-size: cover;

	
}
.baner-full-2 {
	min-height: 17.5rem;
	margin-bottom: -2rem;
	display: block;
	position: relative;
	z-index: 10;
	img{
		display: none;
		@media (max-width: 540px) {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	@media (max-width: 992px) {
		min-height: 22rem;
	}
}