.jump-to {
	padding: 0;
	margin: 1.5rem 0 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;
	li {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 1rem 1.5rem;
		border-radius: 0.5rem;
		border: 1px solid #d9d9d9;
		background: #fff;
		&:hover{
			color: var(--color_3);
			border-color: var(--color_3);
		}
	}
}
