/*-----form-----*/
.forma {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	fieldset {
		border: none;
		padding: 0;
		&.one {
			width: calc(65% - 0.75rem);
		}
		&.two {
			width: calc(35% - 0.75rem);
		}
	}
	label {
		display: block;
	}

	input {
		width: 100%;
		height: 3.25rem;
		padding: 0.625rem 1.625rem;
		border: 1px solid transparent;
		box-sizing: border-box;
		background: #f7f7f7;
		border-radius: 0.875rem;
		font-size: 0.95rem;
		@media (max-width: 680px) {
			padding: 0.625rem 1rem;
		}
		&:focus {
			&::-webkit-input-placeholder {
				opacity: 0;
			}
			&::-moz-placeholder {
				opacity: 0;
			} /* Firefox 19+ */
			&:-moz-placeholder {
				opacity: 0;
			} /* Firefox 18- */
			&:-ms-input-placeholder {
				opacity: 0;
			}
		}
	}

	textarea {
		width: 100%;
		height: 11.875rem;
		padding: 1.25rem 1.625rem;
		border: 1px solid transparent;
		box-sizing: border-box;
		background: #f7f7f7;
		border-radius: 0.875rem;
		font-size: 0.95rem;
		@media (max-width: 680px) {
			padding: 1rem;
		}
		&:focus {
			&::-webkit-input-placeholder {
				opacity: 0;
			}
			&::-moz-placeholder {
				opacity: 0;
			} /* Firefox 19+ */
			&:-moz-placeholder {
				opacity: 0;
			} /* Firefox 18- */
			&:-ms-input-placeholder {
				opacity: 0;
			}
		}
	}

	p.acept {
		margin: 1rem 0 0;
		font-size: 0.75rem;
		line-height: 130%;

		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.wide-row {
	width: 100%;
	margin-bottom: 1.25rem;
	position: relative;
}
.first-row {
	width: calc(50% - 0.625rem);
	margin-bottom: 1.25rem;
	position: relative;
	@media (max-width: 680px) {
		width: 100%;
	}
}
.last-row {
	width: calc(33.33% - 0.625rem);
	margin-bottom: 1.25rem;
	position: relative;
}

.wpcf7-not-valid-tip {
	color: red;
	font-size: 0.75rem;
	margin-left: 0.75rem;
}
.screen-reader-response {
	display: none;
}

.grecaptcha-badge {
	right: -100% !important;
}
