.pros-cons {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	gap:1rem 1.25rem;

	.section-title{
		width: 100%;
		margin-bottom: 1rem;
	}
	.block {
		width: calc(50% - 1rem);
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
		@media (max-width: 480px) {
			width: 100%;
			&:last-child{
				margin-top: 1rem;
			}
		}
		.title {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 0.25rem;
			margin-bottom: 0.5rem;
			color: var(--color_5);
			font-size: 1.125rem;
			font-style: normal;
			font-weight: 700;
			line-height: 1;
		}
		.item {
			display: flex;
			gap: 0.5rem;
			align-items: flex-start;
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			img,
			svg {
				min-width: 1.5rem;
				width: 1.5rem;
				height: auto;
				margin-top: -0.15rem;
			}
		}
	}
}
