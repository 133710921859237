.context-box {
	box-shadow: 0 0.25rem 0.5rem 0 rgba(29, 39, 48, 0.16);
}
.context-menu-title {
	display: flex;
	padding: 1rem;
	font-family: "Inter-Bold";
	text-transform: uppercase;
	font-size: 0.75rem;
	line-height: 1.3;
	border-bottom: 1px solid #e3e7ef;
	background: var(--color_0);
	position: relative;
	cursor: pointer;
	.arrow {
		width: 1.15rem;
		height: 1.15rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: var(--color_8);
		position: absolute;
		top: 50%;
		right: 1rem;
		margin-top: -0.75rem;
		transform: rotate(90deg);
	}
	&.active {
		.arrow {
			transform: rotate(-90deg);
		}
	}
}
.context-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	background: #f7f7f7;
	display: none;
	&.active {
		display: block;
	}
	li {
		border-bottom: 1px solid #e3e7ef;
		a {
			display: flex;
			padding: 1rem;
			font-family: "Inter-Bold";
			text-transform: uppercase;
			font-size: 0.75rem;
			line-height: 1.3;
			&:hover {
				color: var(--color_12);
			}
		}
	}
}
