.payment-item {
	width: calc(25% - 0.75rem);
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	//gap: 1rem;
	border: 1px solid #d9d9d9;
	background-color: #fff;
	overflow: hidden;
	.image {
		border-radius: 0.625rem 0.625rem 0 0;
		img{
			width: 100%;
			aspect-ratio: 6/4;
			object-fit: cover;
		}
	}
	.caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 1rem;
		padding: 1rem;
		.title {
			color: var(--color_7);
			font-size: 1rem;
			font-weight: 700;
			text-transform: uppercase;
			padding-top: 0.625rem;
		}
		.count {
			color: var(--color_11);
			font-size: 0.875rem;
			font-weight: 400;
		}
	}
}
