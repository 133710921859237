.game-type-all {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.game-type {
	width: 100%;
	display: flex;
	gap: 0.625rem;
	@media (max-width: 480px) {
		flex-direction: column;
		gap: 1rem;
	}
	.block-info {
		width: 11.25rem;
		padding: 1.5rem;
		background-color: #d9d9d9;
		border-radius: 0.625rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		//align-items: center;
		@media (max-width: 480px) {
			width: 100%;
		}
		.box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 0.75rem;

			.image {
				width: 6.25rem;
				height: 6.25rem;
				border-radius: 100%;
				background: var(--color_10);
				display: flex;
				align-items: center;
				justify-content: center;
				@media (max-width: 480px) {
				width: 5rem;
				height: 5rem;
			}
				img {
					width: 100%;
					border-radius: 100%;
				}
			}
			.title {
				font-family: "Inter-Bold";
				color: var(--color_7);
				font-size: 1rem;
				text-transform: uppercase;
			}
			.text {
				color: var(--color_5);
				font-size: 0.75rem;
				text-transform: uppercase;
			}
			&:hover {
				.image {
					background: var(--color_3);
				}
			}
		}
	}
	.game-grid {
		width: calc(100% - 11.875rem);
		margin: 0;
		gap: 0.625rem;
		@media (max-width: 480px) {
			width: 100%;
			gap: 1.5rem 1rem;
		}
		.game-item {
			width: calc(25% - 0.5rem);
			@media (max-width: 992px) {
				width: calc(33.33% - 0.5rem);
			}
			@media (max-width: 480px) {
				width: calc(50% - 0.5rem);
			}
			.game-item .title {
				font-size: 0.875rem;
			}
		}
	}
}
