.widget-tags {
	padding: 1rem 0;
	@media (max-width: 992px) {
		width: 50%;
		padding: 0 0.625rem;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
	.title {
		text-transform: uppercase;
		color: var(--color_4);
		font-size: 0.875rem;
		font-weight: 700;
		margin-bottom: 1rem;
		@media (max-width: 992px) {
			font-size: 1.25rem;
		}
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	a {
		height: 1.875rem;
		display: inline-flex;
		align-items: center;
		padding: 0.25rem 0.5rem;
		border-radius: 0.25rem;
		background: #fff;
		border: 1px solid var(--color_3);
		color: var(--color_3);
		&:hover {
			color: var(--color_2);
			border-color: var(--color_2);
		}
	}
}
