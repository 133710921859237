.gallery {
	position: relative;
	.swiper {
		
		width: 100%;
	}
	.mySwiper {
		padding-top: 1rem;
		.swiper-slide {
			border-radius: 0.625rem;
			background-size: cover;
			background-position: 50% 50%;
			height: 7.5rem;
			opacity: 0.6;
		}
		.swiper-slide-thumb-active {
			opacity: 1;
		}
	}

	.mySwiper2 {
		width: 100%;
		.swiper-slide {
			border-radius: 0.625rem;
			background-size: cover;
			background-position: 50% 50%;
			padding-top: 60%;
		}
	}
	.swiper-pagination {
		position: relative;
		width: 100%;
		text-align: center;
		z-index: 99;
		bottom: 0;
		top: 0;
		/* Установите z-index */
		.swiper-pagination-bullet-active {
			background: linear-gradient(264deg, var(--color_2) 3%, var(--color_1) 95.71%);
		}
	}
}
