.page-error {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--color_6);
	color: var(--color_0);
	overflow: hidden;
}

.section-error {
	padding: 3rem 0;
	img {
		width: 100%;
		margin-bottom: 1.5rem;
		@media (max-width: 992px) {
			width: 160%;
			max-width: 160%;
			margin-left: -30%;
		}
		@media (max-width: 680px) {
			width: 200%;
			max-width: 200%;
			margin-left: -50%;
		}
	}
	.no-post {
		max-width: 36rem;
		margin: 0 auto;
		text-align: center;
		@media (max-width: 680px) {
			padding: 0 1.5rem;
		}
	}
	.section-title {
		justify-content: center;
		font-size: 2.5rem;
		font-weight: 900;
		@media (max-width: 680px) {
			font-size: 2rem;
		}
	}
	.big-text {
		font-size: 1.25rem;
		font-weight: bold;
	}
	.button {
		width: 20.875rem;
		height: 4rem;
		font-size: 1.25rem;
		font-weight: bold;
		margin-top: 2rem;
	}
}
