.section-top-page {
	padding-top: 2.5rem;
	padding-bottom: 5.5rem;
	background-color: #fff;
	background-image: url(../img/bg.webp);
	.container {
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
	}
	.text {
		max-width: calc(100% - 19.5rem);
		@media (max-width: 992px) {
			max-width: 100%;
		}
	}
}
.content {
	//position: relative;
	display: flex;
	flex-wrap: wrap;
	border-radius: 0.625rem;

	box-sizing: border-box;
	background: #fafafa;

	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-top: -3.75rem;
	margin-bottom: 4rem;
	gap: 2rem;
	box-sizing: border-box;
	z-index: 10;
	@media (max-width: 1140px) {
		margin-bottom: 0;
	}

	&.content-single {
		margin-top: 0;
		border-radius: 0 0 0.625rem 0.625rem;
	}
	&.content-home {
		margin-top: -6.5rem;
		padding-top: 11rem;
		border-radius: 0 0 0.625rem 0.625rem;
	}
	.content-main {
		width: calc(100% - 19.5rem);
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		@media (max-width: 1140px) {
			width: 100%;
		}

		&.main-guides {
			width: calc(100% - 19.5rem - 15rem);
			@media (max-width: 1140px) {
				width: 100%;
			}
		}
	}
	.right-column {
		width: 17.5rem;
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		@media (max-width: 992px) {
			display: none;
		}
	}
	.left-column {
		width: 13rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		@media (max-width: 1140px) {
			width: 18.75rem;
			position: fixed;
			bottom: 0;
			top: 5rem;
			left: -100%;
			background: var(--color_0);
			z-index: 600;
			padding: 1rem;
			overflow-y: auto;
			margin: 0;
		}
		&.active {
			left: 0;
		}
	}
}
/// grids
.ratings-list {
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	margin: 0;
}

.post-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem 1rem;
	margin: 0;
}

.game-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem 1rem;
	margin: 0;
}

.casino-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem 1.25rem;
	margin: 0;
	@media (max-width: 1140px) {
		gap: 0.5rem 0.8rem;
		padding: 0;
	}
}

.offer-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem 1rem;
	margin: 0;
	@media (max-width: 480px) {
		gap: 1rem;
	}
}

.providers-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0;
}

.payments-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0;
}

.types-games-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0;
}

.licensing-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0;
}

.presets-grid {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;
}

.loads {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
		background-color: rgba(255, 255, 255, 0.8);
	}
}

.right-mobile {
	display: none;
	@media (max-width: 680px) {
		display: block;
		padding: 3.5rem 1.5rem;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 100%;
		z-index: 9997;
		background: #fff;
		.close {
			width: 1.375rem;
			height: 1.375rem;
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			&:before,
			&:after {
				content: "";
				display: block;
				width: 1.375rem;
				height: 2px;
				background: var(--color_4);
				position: absolute;
				transition: 0.6s;
				top: 0.3rem;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}

		.widget-top-casinos {
			width: calc(100% - 3rem);
			min-width: 18.75rem;
			margin: 0 auto;
			max-height: calc(100vh - 7rem);
			overflow-y: auto;
		}
	}
	&.active {
		left: 0;
	}
}

.toggle-right {
	width: 3rem;
	height: 4rem;
	border-radius: 0.75rem;
	background: #272a2c;
	position: absolute;
	z-index: 10;
	top: 50%;
	left: -1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0.5rem;
	&:after,
	&::before {
		content: "";
		min-width: 1rem;
		width: 1rem;
		height: 1rem;
		background-image: url(../img/icons/arrow-white.svg);
		background-size: contain;
		cursor: pointer;
	}
	&:before {
		transform: rotate(180deg);
	}
}

.toggle-guid {
	display: none;
	@media (max-width: 1140px) {
		display: flex;
		width: 20rem;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
}
