.boxes {
	display: flex;
	flex-wrap: wrap;
	gap: 1.25rem;
	.item {
		width: calc(33.33% - 0.875rem);
		padding: 1rem;
		border-radius: 0.75rem;
		background: var(--color_0);
		box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.08);
		text-align: center;
		color: var(--color_7);
		font-size: 0.9375rem;
		@media (max-width: 680px) {
			width: 100%;
			font-size: 1rem;
		}
	}
	.image {
		height: 6.875rem;
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid rgba(230, 230, 230, 0.6);
		img {
			width: auto;
			height: 5rem;
		}
	}
}
