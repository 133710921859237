.licensing-item {
	width: calc(33.33% - 0.875rem);
	padding: 1rem;
	border-radius: 0.75rem;
	background: var(--color_0);
	box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.02);
	border: 1px solid #d9d9d9;
	text-align: center;
	display: block;
	@media (max-width: 680px) {
		width: calc(50% - 0.5rem);
	}

	@media (max-width: 480px) {
		width: 100%;
	}
	.image {
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid rgba(230, 230, 230, 0.6);
		img {
			width: 70%;
			aspect-ratio: 6/4;
			object-fit: cover;
			@media (max-width: 480px) {
				width: 60%;
			}
		}
	}
	.text {
		text-align: center;
		color: var(--color_7);
		font-size: 0.9375rem;
	}
}
