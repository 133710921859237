.content {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	border-radius: 0;
	padding: 1.25rem;
	margin-bottom: 4rem;
	gap: 2rem;
	box-sizing: border-box;
	z-index: 10;
	@media (max-width: 1140px) {
		margin-bottom: 0;
	}
	.content-main {
		width: calc(100% - 19.5rem);
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		@media (max-width: 1140px) {
			width: 100%;
		}
	}
	.right-column {
		width: 17.5rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		@media (max-width: 992px) {
			display: none;
		}
	}
}

.post-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem 1.25rem;
	margin: 1.5rem 0;
	.post {
		width: calc(33% - 0.75rem);
		@media (max-width: 680px) {
			width: calc(33.33% - 0.9375rem);
		}
		@media (max-width: 540px) {
			width: calc(50% - 0.9375rem);
		}
		@media (max-width: 414px) {
			width: 100%;
		}
		&.post-hot {
			width: calc(33.33% - 0.85rem);
			@media (max-width: 992px) {
				width: calc(50.33% - 0.9375rem);
			}
			@media (max-width: 480px) {
				width: 100%;
			}
		}
	}
}

.game-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem 1rem;
	margin: 1.5rem 0;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.casino-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem 1.25rem;
	margin: 1.5rem 0;
	@media (max-width: 1140px) {
		gap: 0.5rem 0.8rem;
		padding: 0;
	}
}

.casino-presets-grid {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 0 1.5rem;
	gap: 1.5rem;
	margin-bottom: 1.5rem;
}

.game-presets-grid {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 0 1.5rem;
	gap: 1.5rem;
	margin-bottom: 1.5rem;
}

.providers-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 1.5rem;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.bonuses-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem 1rem;
	margin: 0 -0.625rem 3rem;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.offer-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem 1rem;
	margin: 0 -0.625rem 0.75rem;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.payments-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1.5rem -0.625rem 0;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.types-games-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1.5rem -0.625rem 0;
	@media (max-width: 480px) {
		gap: 1rem 0;
	}
	.sort-by {
		padding: 0 0.625rem;
		margin-bottom: 0;
	}
	.no-post {
		padding: 1.5rem 0.625rem;
	}
}

.section-top-page {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: #fff;
	background-image: url(../img/bg.webp);
	.container {
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
	}
	.text {
		max-width: calc(100% - 19.5rem);
	}
}

.loads {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
		background-color: rgba(255, 255, 255, 0.8);
	}
}

.right-mobile {
	display: none;
	@media (max-width: 680px) {
		display: block;
		padding: 3.5rem 1.5rem;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 100%;
		z-index: 9997;
		background: #fff;
		.close {
			width: 1.375rem;
			height: 1.375rem;
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			&:before,
			&:after {
				content: "";
				display: block;
				width: 1.375rem;
				height: 2px;
				background: var(--color_4);
				position: absolute;
				transition: 0.6s;
				top: 0.3rem;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}

		.widget-top-casinos {
			width: calc(100% - 3rem);
			min-width: 18.75rem;
			margin: 0 auto;
			max-height: calc(100vh - 7rem);
			overflow-y: auto;
		}
	}
	&.active {
		left: 0;
	}
}

.toggle-right {
	width: 3rem;
	height: 4rem;
	border-radius: 0.75rem;
	background: #272a2c;
	position: absolute;
	z-index: 10;
	top: 50%;
	left: -1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0.5rem;
	&:after,
	&::before {
		content: "";
		min-width: 1rem;
		width: 1rem;
		height: 1rem;
		background-image: url(../img/icons/arrow-white.svg);
		background-size: contain;
		cursor: pointer;
	}
	&:before {
		transform: rotate(180deg);
	}
}
