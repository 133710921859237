/*-----title-----*/
.page-title {
	width: 100%;
	font-family: "Inter-Semibold";
	color: var(--color_5);
	font-size: 2.25rem;
	line-height: 1.2;
	position: relative;
	padding-bottom: 0.75rem;
	&:after {
		content: "";
		display: block;
		width: 9.375rem;
		height: 1px;
		border-bottom: 3px dashed var(--color_3);
		position: absolute;
		bottom: 0;
		left: 0;
	}
	@media (max-width: 680px) {
		font-size: 2rem;
	}
	@media (max-width: 414px) {
		font-size: 1.8rem;
	}
}

.section-title {
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 1.5rem */
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	@media (max-width: 680px) {
		font-size: 1.2rem;
	}
}

.widget-title {
	width: 100%;
	color: var(--color_7);
	font-size: 1.25rem;
	font-family: "Inter-Bold";
	line-height: 156%;
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media (max-width: 680px) {
		font-size: 1.1rem;
	}
}
.nav-title {
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.28;
	text-transform: uppercase;
	color: var(--color_5);
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	padding: 1rem;
	img,
	svg {
		min-width: 1rem;
		width: 1rem;
		height: auto;
		//margin-top: -0.125rem;
	}
}
