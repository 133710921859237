.content-single {
	.page-title {
		padding: 0;
		&:after {
			display: none;
		}
	}
	article {
		margin-bottom: 1.5rem;
		h1 {
			max-width: 46.875rem;
			margin-bottom: 0;
		}
	}
	.previos {
		color: var(--color_5);
	}
	article {
		.single-meta {
			padding: 1.5rem 0 1rem;
			
			border-top: 1px solid var(--color_2);
		}
	}
}

.single-meta {
	display: flex;
	gap: 0.75rem;
	align-items: center;
	font-size: 0.875rem;
	color: var(--color_8);
	.origin {
		line-height: 114.286%;
		text-transform: uppercase;
		font-weight: 700;
	}
	.date {
		text-transform: uppercase;
		display: flex;
		gap: 0.25rem;
		&:before {
			content: "";
			height: 0.1875rem;
			width: 0.1875rem;
			border-radius: 100%;
			background: var(--color_8);
			margin-top: 0.1875rem;
		}
	}
	.read {
		border-left: 1px solid #a2aeb7;
		padding-left: 0.75rem;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		&:before {
			content: "";
			width: 1rem;
			height: 1rem;
			background-image: url(../img/icons/clock.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
.single-meta-bottom {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid var(--color_5);
	font-size: 0.875rem;
	line-height: 1;
	color: var(--color_8);

	.item {
		width: 100%;
		display: flex;
		//align-items: center;
		gap: 0.75rem;
		.label {
			height: 1.875rem;
			font-weight: 700;
			display: inline-flex;
			align-items: center;
			@media (max-width: 414px) {
				width: 30%;
			}
		}
		.tags {
			width: 70%;
		}
		.relate {
			min-height: 1.875rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			color: var(--color_5);
			@media (max-width: 414px) {
				width: 70%;
			}
			a {
				&:after {
					content: ",";
					margin-right: 0.3125rem;
				}
				&:last-child {
					&::after {
						display: none;
					}
				}
				&:hover {
					color: var(--color_2);
				}
			}
		}
		&.share {
			justify-content: flex-end;
			align-items: center;
			.share-box {
				display: flex;
				gap: 0.75rem;
				a {
					display: inline-flex;
					align-items: center;
					color: var(--color_8);
					img,
					svg {
						width: auto;
						height: 1.25rem;
					}
					&:hover {
						color: var(--color_2);
					}
				}
			}
		}
	}
}
.single-image {
	padding-top: 50%;
	background-size: cover;
	background-position: 50% 50%;
	border-radius: 0.25rem;
	margin-bottom: 1.5rem;
}
