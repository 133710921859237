.widget-tags {
	width: 100%;
	@media (max-width: 992px) {
		width: 50%;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	a {
		height: 1.875rem;
		display: inline-flex;
		align-items: center;
		padding: 0.25rem 0.5rem;
		border-radius: 0.25rem;
		background: #fff;
		border: 1px solid var(--color_3);
		color: var(--color_3);
		&:hover {
			color: var(--color_2);
			border-color: var(--color_2);
		}
	}
}
