.game-item {
	width: calc(25% - 0.75rem);
	text-align: center;
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	@media (max-width: 680px) {
		width: calc(33.33% - 0.75rem);
	}

	@media (max-width: 480px) {
		width: calc(50% - 0.5rem);
	}
	&:hover {
		background: var(--color_0);
	}
	.image {
		display: block;
		width: 100%;
		aspect-ratio: 1/1;
		border-radius: 0.625rem;
		background-size: cover;
		background-position: 50% 50%;
		margin-bottom: 0.5rem;
		@media (max-width: 380px) {
			height: 10.5rem;
		}
	}
	.title {
		color: var(--color_7);
		font-size: 1rem;
		font-weight: 700;
		text-transform: uppercase;
		padding-top: 0.625rem;
	}
	.desc {
		color: var(--color_11);
		font-size: 0.75rem;
		font-weight: 400;
		text-transform: uppercase;
		padding: 0;
	}
}
