.preview-game {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	.game-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 1rem;
		.item {
			width: calc(33.33% - 1rem);
			@media (max-width: 480px) {
				width: calc(50% - 0.5rem);
			}
			@media (max-width: 414px) {
				width: 100%;
			}
			&.game-logo {
				@media (max-width: 480px) {
					width: calc(50% - 0.5rem);
				}
				@media (max-width: 414px) {
					width: 100%;
				}
				img {
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: cover;
					border-radius: 0.2rem;
				}
			}
			.title {
				font-family: "Inter-Bold";
				font-size: 1rem;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				&:before {
					content: "";
					width: 1.5rem;
					height: 1.5rem;
					background-image: url(../img/icons/game.svg);
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
			ul {
				margin: 1rem 0;
				padding: 0;
				list-style: none;
				display: flex;
				flex-direction: column;
				gap: 0.75rem;
				li {
					color: var(--color_5);
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 0.5rem;
					padding: 0;
					&:before {
						display: none;
					}
					.no {
						width: 1.125rem;
						height: 1.125rem;
						background-image: url(../img/icons/no.svg);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.yes {
						width: 1.125rem;
						height: 1.125rem;
						background-image: url(../img/icons/yes.svg);
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
			}
			.button {
				width: 100%;
			}
		}
	}

	.cards {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		.card {
			position: relative;
			display: flex;
			width: calc(25% - 0.75rem);
			min-height: 5.9375rem;
			padding: 0.5rem 1rem;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.5rem;
			overflow: hidden;
			border-radius: 0.1875rem;
			border-left: 0.25rem solid var(--color_11);
			background-color: #f0f0f0;
			background-repeat: no-repeat;
			background-position: 100% 100%;
			background-size: 5rem 4.125rem;
			@media (max-width: 992px) {
				width: calc(50% - 0.5rem);
			}
			@media (max-width: 414px) {
				width: 100%;
			}
			&:nth-child(1) {
				background-image: url(../img/card-1.svg);
			}
			&:nth-child(2) {
				background-image: url(../img/card-2.svg);
			}
			&:nth-child(3) {
				background-image: url(../img/card-3.svg);
				.card__desc {
					font-size: 1rem;
				}
			}
			&:nth-child(4) {
				background-image: url(../img/card-4.svg);
			}
		}
		.card__title-box {
			.card__title {
				margin: 0;
				color: var(--color_8);
				font-size: 0.875rem;
				font-weight: 700;
				line-height: 1;
				text-transform: none;
			}
			.card__info {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1.5rem;
				height: 1.5rem;
				border: 0.1875rem solid var(--color_8);
				border-radius: 50%;
				font-family: monospace;
				font-weight: 600;
				color: var(--color_8);
				font-size: 0.875rem;
				cursor: pointer;
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				&:hover {
					color: var(--color_2);
					border-color: var(--color_2);
				}
			}
		}
		.card__desc {
			display: flex;
			align-items: center;
			gap: 1rem;
			color: var(--color_8);
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1.75rem;
			text-transform: uppercase;
			svg,
			img {
				position: static;
				min-width: 1.75rem;
				width: 1.75rem;
				height: 1.75rem;
				path {
					fill: var(--color_11);
				}
			}
		}
		.card__link {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: var(--color_11);
			font-size: 0.75rem;
			font-weight: 400;
			line-height: 0.875rem;
			&:before {
				content: "➞";
				transform: rotate(90deg);
				font-size: 1rem;
			}
			&:hover {
				color: var(--color_2);
			}
		}
	}
}

.provider-game {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	.box {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 1rem;
		border-radius: 0.1875rem;
		background: #f0f0f0;
		overflow: hidden;
		@media (max-width: 480px) {
			flex-wrap: wrap;
		}
		.image {
			min-width: 8.75rem;
			width: 8.75rem;
			height: 8.75rem;
			background-size: cover;
			background-position: 50% 50%;
		}
		.caption {
			padding: 1rem 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 0.5rem;
			.info {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0.5rem;
				color: var(--color_11);
				font-size: 0.875rem;
			}
		}
		.title {
			color: var(--color_5);
			font-size: 1.5rem;
			font-style: normal;
			font-weight: 700;
			line-height: 116.667%;
			text-transform: uppercase;
		}
		.buttons {
			min-width: 18rem;
			width: 18rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			margin-left: auto;
			padding: 1rem;
			@media (max-width: 480px) {
				width: 100%;
			}
		}
		.button {
			width: 15rem;
			height: 2.625rem;
			display: flex;
			justify-content: flex-start;
			font-size: 0.875rem;
			text-transform: none;
			gap: 0.5rem;
			@media (max-width: 480px) {
				width: 100%;
			}
			svg,
			img {
				min-width: 1.5rem;
				width: 1.5rem;
				height: auto;
				&.arrows {
					min-width: 1.5rem;
					width: 1.5rem;
					height: auto;
					margin-left: auto;
				}
			}
		}
	}
}

.frame-box {
	width: 100%;
	aspect-ratio: 6/4;
	background-color: var(--color_6);
	margin: 1.5rem auto;
	position: relative;
	border: 1px solid rgba(255, 255, 255, 0.1);
	position: relative;
	z-index: 10;
	.title {
		width: 100%;
		min-height: 2.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 0.5rem;
		font-size: 0.875rem;
		font-weight: 700;
		color: var(--color_10);
		padding: 0.4rem 2rem;
		span {
			color: var(--color_0);
			font-weight: 400;
			font-size: 0.75rem;
		}
	}

	.box {
		position: absolute;
		top: 2.5rem;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: 50% 50%;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.play-free {
			width: 12.5rem;
			height: 3rem;
			border-radius: 0.125rem;
			border: 1px solid var(--color_3);
			background: linear-gradient(270deg, #21c12e 0%, #0f8815 100%);
			color: var(--color_0);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
			&:hover {
				background: linear-gradient(270deg, #0f8815 0%, #0f8815 100%);
				border-color: #0f8815;
				transition: 0.6s;
			}
			.ico {
				width: 1.5rem;
				height: 1.5rem;
				background-image: url(../img/icons/play.svg);
				background-repeat: no-repeat;
				background-size: contain;
			}
			.text {
				font-size: 0.625rem;
				font-weight: 400;
				span {
					display: flex;
					font-size: 0.875rem;
					font-weight: 700;
				}
			}
		}
	}
	.button-screen {
		position: absolute;
		right: 1rem;
		width: 1rem;
		height: 1rem;
		border: none;
		background-color: transparent;
		background-image: url(../img/icons/up-screen.svg);
		background-size: contain;
		background-repeat: no-repeat;
		&.active {
			background-image: url(../img/icons/close-screen.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	&.active {
		position: fixed;
		z-index: 99999999;
		width: 100%;
		height: calc(100vh - 3rem);
		top: 3rem;
		right: 0;
		left: 0;
		bottom: 0;
		max-width: 100%;
		.frame-box {
			width: 100%;
			aspect-ratio: 6/3;
		}
		.play-free {
			transform: scale(1.5);
		}
	}
}
