/*-----footer-----*/
footer {
	display: flex;
	justify-content: center;
	width: 100%;
	color: var(--color_0);
	background-color: var(--color_1);
	position: relative;
	z-index: 10;
	.footer-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 81.25rem;
		width: 100%;
		padding-top: 2.8125rem;

		.logo_footer {
			margin-bottom: 1.875rem;
		}

		.lists {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			gap: 1.25rem;
			padding: 1.875rem 0;
			border-top: 0.125rem solid rgb(255, 255, 255, 0.2);
			border-bottom: 0.125rem solid rgb(255, 255, 255, 0.2);
			.widget {
				width: calc(25% - 1rem);
				@media (max-width: 992px) {
					width: calc(50% - 1rem);
					margin-bottom: 1.5rem;
				}
				@media (max-width: 540px) {
					width: 100%;
					margin-bottom: 1.5rem;
				}
				.widget-title {
					display: flex;
					gap: 0.5rem;
					align-items: center;
					text-transform: uppercase;
					font-size: 1rem;
					color: var(--color_0);
					margin-bottom: 1rem;
					img,
					svg {
						width: 1rem;
						height: auto;
						path {
							fill: var(--color_12);
						}
					}
				}
			}
			.list {
				list-style-type: none;

				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				margin: 0;
				@media (max-width: 540px) {
					width: 100%;
					border-bottom: 0.125rem solid rgb(255, 255, 255, 0.2);
					&:last-child {
						border-bottom: none;
					}
				}
				li {
					a {
						font-size: 0.875rem;
						font-weight: 500;
						line-height: 1; /* 16.0.5rem */

						&:hover {
							color: rgba(255, 255, 255, 0.6);
						}
					}
					.sub-menu {
						list-style: none;
						padding: 0;
						margin-top: 1rem;
						@media (max-width: 540px) {
							margin: 1.5rem 0;
						}
						li {
							//margin-bottom: 0.375rem;
							@media (max-width: 540px) {
								margin-bottom: 0.6rem;
							}
							a {
								display: flex;
								align-items: center;
								gap: 0.5rem;
								text-transform: none;
								img,
								svg {
									width: 1.5rem;
									height: auto;
								}
							}
						}
					}
				}
			}
			.widget:nth-child(1) {
				width: 40%;
				@media (max-width: 992px) {
					width: 100%;
					margin-bottom: 1.5rem;
				}

				.list {
					flex-direction: row;
					flex-wrap: wrap;
					li {
						width: calc(50% - 0.5rem);
					}
				}
			}
		}
	}
}

.subscribe {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2.5rem 0;
	border-bottom: 0.125rem solid rgba(255, 255, 255, 0.2);
	@media (max-width: 540px) {
		padding: 1.5rem 0;
	}
	.title {
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 120%;
	}

	.input-box {
		display: flex;
		align-items: flex-start;
		gap: 0.625rem;
		margin-top: 1rem;
		@media (max-width: 540px) {
			flex-wrap: wrap;
		}
		.wpcf7-form-control-wrap {
			@media (max-width: 540px) {
				width: 100%;
			}
		}
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 26.875rem;
			padding: 0.875rem;
			border-radius: 0.625rem;
			border: 1px solid var(--color_0);
			background: rgba(255, 255, 255, 0.1);
			box-shadow: -0.3125rem -0.3125rem 15.625rem 0 rgba(255, 255, 255, 0.02) inset;
			backdrop-filter: blur(1.5625rem);
			@media (max-width: 540px) {
				width: 100%;
			}
			&:focus {
				border-color: var(--color_2);
			}
		}
		input:focus::-webkit-input-placeholder {
			color: transparent;
		}
		input:focus::-moz-placeholder {
			color: transparent;
		}
		input:focus:-moz-placeholder {
			color: transparent;
		}
		input:focus:-ms-input-placeholder {
			color: transparent;
		}
		.submit {
			display: flex;
			width: 12.5rem;
			padding: 0.9375rem 2.5rem;
			justify-content: center;
			align-items: center;
			border: 0;
			border-radius: 0.625rem;
			background: linear-gradient(264deg, var(--color_2) 3%, var(--color_1) 95.71%);
			@media (max-width: 540px) {
				width: 100%;
			}
			&:hover {
				background: linear-gradient(264deg, var(--color_1) 3%, var(--color_1) 95.71%);
			}
		}
	}

	.checkbox-wrap {
		margin-top: 1.25rem;
	}
}
.wpcf7-spinner {
	display: none;
}
.wpcf7-list-item {
	display: inline-block;
	margin: 0;
}
.wpcf7-acceptance label > input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.wpcf7-acceptance label > span.wpcf7-list-item-label {
	display: flex;
	align-items: flex-start;
	position: relative;
	align-items: center;
	color: rgba(255, 255, 255, 0.6);
}

.wpcf7-acceptance label > span.wpcf7-list-item-label::before {
	content: "";
	display: inline-block;
	width: 1.25rem;
	height: 1.25rem;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #a3a7be;
	border-radius: 0.25rem;
	margin-right: 0.75rem;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 60% 60%;
}

.wpcf7-acceptance label > input:checked + span.wpcf7-list-item-label::before {
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1499 0.0336408C10.9769 0.0899873 11.2201 -0.176468 7.70606 3.80576C5.74764 6.0251 4.94156 6.91796 4.85125 6.9679C4.67866 7.06331 4.43054 7.06244 4.26552 6.9658C4.1989 6.9268 3.44254 6.2499 2.58474 5.46161C1.55898 4.51896 0.979521 4.00758 0.891938 3.96772C0.599301 3.83449 0.222322 3.97239 0.073838 4.26701C-0.0182869 4.44985 -0.0247071 4.67919 0.0573937 4.85587C0.096097 4.93915 0.718803 5.55825 2.12433 6.91084C3.23064 7.97552 4.19229 8.88142 4.26125 8.92394C4.35904 8.98422 4.42669 9.00095 4.56836 8.99996C4.76691 8.99854 4.88241 8.95014 5.01963 8.81085C5.06727 8.76248 6.62739 6.99978 8.48658 4.89369C10.7672 2.31023 11.8889 1.01738 11.9346 0.919752C12.0261 0.724302 12.0219 0.560953 11.9199 0.355514C11.7754 0.0644736 11.4601 -0.0673312 11.1499 0.0336408Z' fill='white'/%3E%3C/svg%3E%0A");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.wpcf7-acceptance label > input:disabled + span.wpcf7-list-item-label::before {
	background-color: #e9ecef;
}

.wpcf7 form .wpcf7-response-output {
	margin: 1rem 0 0;
	padding: 1rem;
}

.footer-brands {
	width: 100%;
	padding: 2.5rem 0;
	border-bottom: 0.125rem solid rgba(255, 255, 255, 0.2);
	@media (max-width: 540px) {
		padding: 1.5rem 0;
	}
	p {
		opacity: 0.7;
		font-size: 0.875rem;
		font-weight: 400;
		padding: 0;
		margin: 0;
	}
	ul {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem;
		padding: 1rem;
		margin: 0;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 540px) {
			justify-content: flex-start;
			gap: 2rem;
			padding: 1.5rem 0 0;
		}
		li {
			img {
				@media (max-width: 992px) {
					max-height: 2.4rem;
				}
			}
		}
	}
}

.copyright {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 2.5rem 0;
	@media (max-width: 992px) {
		flex-wrap: wrap;
	}
	@media (max-width: 540px) {
		padding: 1.5rem 0 2.5rem;
	}
	.text {
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 120%; /* 14.0.25rem */
		max-width: 50rem;
		opacity: 0.7;
		@media (max-width: 992px) {
			width: 100%;
			margin-bottom: 1.5rem;
		}
		p {
			margin: 0;
		}
	}
	.years {
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 100%; /* 1.125rem */
		text-transform: uppercase;
		&:before {
			content: "©";

			opacity: 0.7;
			margin-right: 4px;
		}
	}
}
