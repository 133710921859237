.widget-latest-articles {
	width: 100%;
	@media (max-width: 992px) {
		width: 50%;
	}
	@media (max-width: 480px) {
		width: 100%;
	}


	.post-hot {
		width: 100%;
	}

	.post-popular {
		display: grid;
		grid-template: 1fr / 1fr;
		column-gap: 0.5rem;
		padding: 1rem 0;
		border-top: 1px solid #f1f1f1;
		border-bottom: none;
		.image {
			display: none;
		}
		.subtitle-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.75rem;
		}
	}

	.button {
		width: 13.75rem;
		margin: 0.5rem auto 0;
		display: flex;
	}
}
