.dictionary-head{
box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.05);
}
.dictionary-list {
	list-style: none;
	padding: 0;
	margin: 1rem 0 0;
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--color_3);
	li {
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		color: var(--color_3);
		flex-grow: 1;
		cursor: pointer;
		&.active,
		&:hover {
			background: var(--color_3);
			color: #fff;
		}
	}
}
.dictionary-search{
	    
	input{
		width: 100%;
		min-height: 2.5rem;
		background: #fff;
		color: var(--color_1);
		padding: 0.25rem 1rem;
		border: none;

	}
}
.dictionary{
	p{
		margin-top: 0;
	}
}

