.questions {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	.accordion {
		.accordion-title-box {
			position: relative;
			display: flex;
			padding: 0 1rem;
			justify-content: space-between;
			align-items: center;
			height: 3.4375rem;
			border-radius: 0.1875rem;
			background: var(--color_0);
			box-shadow: 0 1px 0 0 #e3e7ef;
			cursor: pointer;
			gap: 1.5rem;
			.accordion-title {
				color: var(--color_5);
				font-size: 1rem;
				font-weight: 700;
				letter-spacing: 0.08px;
				text-transform: uppercase;
				margin: 0;
				@media (max-width: 480px) {
					font-size: 0.88rem;
				}
			}
			.accordion-arrow {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1.125rem;
				height: 1.125rem;
				transform: rotate(180deg);
				&:after {
					content: "";
					position: absolute;
					width: 0.125rem;
					height: 0.75rem;
					transform: translateX(-0.1875rem) rotate(45deg);
					background-color: var(--color_8);
				}
				&:before {
					content: "";
					position: absolute;
					width: 0.125rem;
					height: 0.75rem;
					transform: translateX(0.25rem) rotate(-45deg);
					background-color: var(--color_8);
				}
			}
			.accordion-arrow_active {
				transform: none;
			}
		}
		.accordion-text {
			display: none;
			color: var(--color_7);
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.125rem;
			letter-spacing: 0.08px;
			padding: 1rem 2rem;
			margin: 0;
			border-radius: 0 0 0.1875rem 0.1875rem;
			background: #e3e7ef;
			box-shadow: 0 0.25rem 1.875rem 0 rgba(0, 0, 0, 0.08);
			transition: all 0.3s ease-in-out;
			@media (max-width: 480px) {
				padding: 1rem;
			}
			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
			&.accordion-text-visible {
				display: flex;
			}
		}
	}
}
