.widget-casino-news {
	width: 100%;
	//margin: 1.5rem 0 0;
	padding: 1rem 0;
	//border-top: 1px solid #e3e7ef;
	@media (max-width: 992px) {
		width: 50%;
	}
		@media (max-width: 480px) {
		width: 100%;
		margin: 1rem 0;
	}
	.widget-title {
		text-transform: uppercase;
		margin-bottom: 1rem;
	}


.post-popular {
	display: grid;
	grid-template: 1fr 1fr / 1fr 5rem;
	column-gap: 0.5rem;
	padding: 0.5rem 0;
	border-top: 1px solid #f1f1f1;
	border-bottom: none;
}

.button{
	width: 13.75rem;
	margin: 1rem auto 0;
	display: flex;
}
}
