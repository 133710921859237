.offer-item {
	width: calc(50% - 0.5rem);
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	//gap: 1rem;
	border: 1px solid #d9d9d9;
	background-color: #fff;
	@media (max-width: 480px) {
		width: 33.33%;
	}
	@media (max-width: 414px) {
		width: 50%;
	}
	&:hover {
		background: var(--color_0);
	}
	.image {
		display: block;
		width: 100%;
		aspect-ratio: 6/4;
		border-radius: 0.625rem  0.625rem 0 0;
		background-size: cover;
		background-position: 50% 50%;

		@media (max-width: 380px) {
			height: 10.5rem;
		}
	}

	.desc {
		width: 100%;
		color: #c3c3c3;
		font-size: 0.75rem;
		font-weight: 400;
		text-transform: uppercase;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: space-between;
	}
	.title {
		color: var(--color_7);
		font-size: 1rem;
		font-weight: 700;
		text-transform: uppercase;

	}
	.more{
		color: var(--color_12);
		&:hover{
		color: var(--color_1);	
		}
	}
}
