$general-font: "Inter-Regular", sans-serif;

$inter300: "Inter-Regular-Light";
$inter400: "Inter-Regular";
$inter500: "Inter-Regular-Medium";
$inter600: "Inter-Regular";
$inter700: "Inter-Regular";

$openSans: "OpenSans";

$baseFontSize: 16; // базовый размер шрифта

/* функция перевода font px в rem */

@function pxToRem($pxValue) {
	@return calc($pxValue / $baseFontSize) * 1rem;
}
