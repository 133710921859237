.example {
	padding: 1.5rem;
	border-radius: 0.25rem;
	border: 1px solid var(--color_12);
	background: #e7f9e8;
	color: var(--color_5);

	.widget-title,
	h3 {
		margin: 0;
		display: flex;	
		gap: 0.5rem;

		img,
		svg {
			min-width: 1.25rem;
			width: 1.25rem;
			height: auto;
		}
	}
	p {
		margin: 0.5rem 0 0;
	}

	a {
		color: var(--color_2);
		text-decoration: none;
	}
}
