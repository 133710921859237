.section-related {
	margin: 2rem 0;
}
.slider-related {
	position: relative;

	.related-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}

	.slider-nav {
		position: relative;
		display: flex;
		align-items: center;
		gap: 1.25rem;
		.swiper-button-next,
		.swiper-button-prev {
			position: relative;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 1.25rem;
			height: 1.25rem;

			&:after {
				font-size: 1.125rem !important;
				color: var(--color_5);
			}
		}
	}

	.swiper-pagination {
		position: relative;
		bottom: 0;
		margin-top: 1rem;
	}
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
	.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		width: 2rem;
		height: 0.25rem;
		border-radius: 1px;
		background: var(--color_7);
		@media (max-width: 992px) {
			width: 1rem;
		}
		&.swiper-pagination-bullet-active {
			background: var(--color_2);
		}
	}
}
