.tabs-info {
	.tabs {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 1rem;
		@media (max-width: 680px) {
			flex-wrap: wrap;
		}
	}

	.tabs-list {
		width: calc(40% - 0.5rem);
		margin: 0;
		padding: 0;
		@media (max-width: 680px) {
			display: none;
		}
		.li_tab {
			padding: 1rem;
			margin: 0 0 1rem;
			border-radius: 0.625rem;
			background: var(--color_0);
			color: var(--color_7);
			font-size: 0.875rem;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.5rem;
			cursor: pointer;
			&.active {
				background: var(--color_2);
				color: var(--color_0);
			}
			&:before {
				display: none;
			}
			&:after {
				content: "";
				min-width: 2rem;
				width: 2rem;
				height: 2rem;
				border-radius: 100%;
				background-color: #f1f1f1;
				background-image: url(../img/icons/arrow-right.svg);
				background-size: 70% 70%;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.tabs-flex {
		width: calc(60% - 0.5rem);
		@media (max-width: 680px) {
			display: none;
		}
		.block {
			padding: 1rem;
			border-radius: 0.625rem;
			background: var(--color_0);
			display: flex;
			gap: 1rem;

			.icon {
				width: 5rem;
				min-width: 5rem;
				@media (max-width: 680px) {
					width: 3rem;
					min-width: 3rem;
				}
				img {
					width: 100%;
					height: auto;
				}
			}

			.text {
				color: var(--color_7);
				p {
					margin: 0;
				}
			}
		}
	}

	.accordion {
		display: none;
		@media (max-width: 680px) {
			display: block;
			width: 100%;
			margin-bottom: 1rem;
			.accordion-title-box {
				padding: 1rem;
				border-radius: 0.7rem;
				background: var(--color_0);
				color: var(--color_7);
				font-size: 0.9rem;
				font-weight: 700;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 0.5rem;
				cursor: pointer;
				box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
				&::after {
					content: "";
					min-width: 2rem;
					width: 2rem;
					height: 2rem;
					border-radius: 100%;
					background-color: #f1f1f1;
					background-image: url(../img/icons/arrow-right.svg);
					background-size: 70% 70%;
					background-repeat: no-repeat;
					background-position: 50% 50%;
					transform: rotate(90deg);
					transition: 0.3s;
				}
				&.active {
					background: var(--color_2);
					color: var(--color_0);
					&:after {
						transform: rotate(-90deg);
						transition: 0.3s;
					}
				}
			}

			.accordion-text {
				display: none;
				border-radius: 0.7rem;
				transition: all 0.3s ease-in-out;
				box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.accordion-text-visible {
				display: flex;
				margin-top: 0.5rem;
				padding: 1rem;
			}
		}
	}
}
