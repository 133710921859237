.provider-item {
	width: calc(50% - 0.5rem);
	display: flex;
	gap: 1rem;
	border-radius: 0.5rem;
	background: #fff;
	border: 1px solid #d9d9d9;
	overflow: hidden;
	@media (max-width: 680px) {
		width: 100%;
	}

	.image {
		min-width: 8.75rem;
		width: 8.75rem;
		height: 8.75rem;
		background-size: cover;
		background-position: 50% 50%;
		border-right: 1px solid #d9d9d9;
	}
	.caption {
		padding: 1rem 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0.5rem;
		.info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 0.5rem;
			color: var(--color_11);
			font-size: 0.875rem;
			svg,
			img {
				min-width: 1.2rem;
				width: 1.2rem;
				height: auto;
				&.arrows {
				min-width: 1.1rem;
				width: 1.1rem;
					height: auto;
					path {
						fill: var(--color_11);
					}
				}
			}
		}
	}
	.title {
		color: var(--color_5);
		font-size: 1.2rem;
		font-style: normal;
		font-weight: 700;
		line-height: 116.667%;
		text-transform: uppercase;
	}
	.buttons {
		min-width: 18rem;
		width: 18rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0.5rem;
		margin-left: auto;
		padding: 1rem;
	}
	.button {
		width: 15rem;
		height: 2.625rem;
		display: flex;
		justify-content: flex-start;
		font-size: 0.875rem;
		text-transform: none;
		gap: 0.5rem;
		svg,
		img {
			min-width: 1.5rem;
			width: 1.5rem;
			height: auto;
			&.arrows {
				min-width: 1.5rem;
				width: 1.5rem;
				height: auto;
				margin-left: auto;
			}
		}
	}
}
