.video-box {
	.caption {
		text-align: center;
		font-size: 0.875rem;
		margin-top: 0.5rem;
		color: var(--color_5);
	}

	.video__media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		-o-object-position: center;
		object-position: center;
		-o-object-fit: cover;
		object-fit: cover;
		margin: 0 !important;
		border-radius: 0.5rem;
	}
	.video__button {
		width: 7.5rem;
		height: 6.25rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 1.5rem;
		background: rgba(255, 255, 255, 0.5);
		position: absolute;
		margin: auto;
		border: none;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		cursor: pointer;
		@media (max-width: 480px) {
			width: 5.5rem;
			height: 4rem;
			border-radius: 1rem;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-top: 1.875rem solid transparent;
			border-left: 3.125rem solid #1a132f;
			border-bottom: 1.875rem solid transparent;
			margin-left: 0.3125rem;
			@media (max-width: 480px) {
				border-top: 0.875rem solid transparent;
				border-left: 1.5rem solid #1a132f;
				border-bottom: 0.875rem solid transparent;
			}
		}
	}
	.video--enabled {
		cursor: pointer;
	}

	.video {
		width: 100%;
		height: 0;
		padding-bottom: 64%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		justify-content: space-around;
		overflow: hidden;
		border-radius: 0.5rem;
		&:hover {
			.video__button {
				background: var(--color_0);
				&:focus {
					background: var(--color_0);
				}
			}
		}
	}
}
