.pagination {
	width: 100%;
	//margin: 1.5rem 0;
	.nav-links {
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 0.625rem;
		.prev,
		.next {
			color: var(--color_1) !important;
			background-image: url(../img/icons/arrow-right.svg);
			background-size: 1rem;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
		.prev {
			transform: rotate(180deg);
		}
		.page-numbers {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.875rem;
			height: 1.875rem;
			color: var(--color_5);

			font-size: 1rem;
			font-weight: 600;
		}
		.current {
			border-radius: 0.1875rem;
			background-color: var(--color_2);
			color: var(--color_0);
		}
	}
}
