.column-stiky {
	position: sticky;
	top: 9.375rem;
}

.casino-info {
	display: flex;
	padding: 0 0.625rem;
	margin-bottom: 0.5rem;

	.image {
		width: 3.75rem;
		height: 3.75rem;
		border-radius: 0.75rem;
		background-color: var(--color_9);
		background-size: cover;
		background-position: 50% 50%;
	}
	.box {
		width: calc(100% - 4.25rem);
		padding: 0.5rem;
	}
	.title {
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 0.5rem;
	}
	.rating-box {
		display: flex;
		align-items: center;
		gap: 0.3125rem;
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		color: var(--color_5);
	}
}

.rating {
	height: 1.375rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5;
	color: var(--color_0);
	border-radius: 0.1875rem;
	gap: 0.25rem;
	&:before {
		content: "";
		width: 0.875rem;
		height: 0.875rem;
		margin-top: -0.125rem;
		background-image: url(../img/icons/star.svg);
		background-size: contain;
		background-position: 50% 50%;
	}
	&.green {
		background: #1bae72;
	}
	&.yellow {
		background: #ffe633;
	}
	&.red {
		background: #f64747;
	}
}

.bonus-offer {
	max-width: 24rem;
	height: 2.75rem;
	border-radius: 0.1875rem;
	padding: 0.5rem 1rem;
	background: var(--color_1);
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 2rem;
	@media (max-width: 480px) {
		max-width: 100%;
	}
	img,
	svg {
		min-width: 1.5rem;
		width: 1.5rem;
		height: auto;
	}
	.text {
		color: var(--color_0);
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.button {
		min-width: 5.625rem;
		font-size: 0.625rem;
		margin-left: auto;
		padding: 0.4rem;
	}
}

.bonuses {
	display: flex;
	flex-wrap: wrap;
	.section-title {
		width: 100%;
		margin-bottom: 1.5rem;
		img,
		svg {
			width: 1.5rem;
			height: auto;
			margin-top: -2px;
		}
	}
	.item {
		width: 100%;
		padding: 0.5rem 1rem;
		margin-bottom: 0.5rem;
		border-radius: 0.125rem;
		border-left: 0.25rem solid var(--color_8);
		background-color: #fbfbfb;
		box-shadow: 0 1px 0 0 #e3e7ef;
		background-repeat: no-repeat;
		background-position: 100% 100%;
		position: relative;
		&:hover {
			//box-shadow: 0 0 1rem  0 rgba(0, 0, 0, 0.05);
		}

		@media (max-width: 992px) {
			width: 50%;
		}
		@media (max-width: 992px) {
			width: 100%;
		}
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: var(--color_4);
			font-size: 0.875rem;
			font-weight: 700;
			line-height: 1; /* 0.875rem */
			text-transform: uppercase;
			margin-bottom: 0.25rem;
			.arrow {
				width: 1.25rem;
				height: 1.25rem;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: var(--color_8);
				position: absolute;
				top: 50%;
				right: 1rem;
				margin-top: -0.75rem;
				&:hover {
					color: var(--color_4);
				}
			}
		}
		.text {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 142.857%;
			text-transform: uppercase;
			color: var(--color_12);
		}

		&.bonus-1 {
			border-left-color: var(--color_2);
			background-image: url(../img/bonuses-1.svg);
		}
		&.bonus-2 {
			border-left-color: var(--color_2);
			background-image: url(../img/bonuses-1.svg);
		}
		&.bonus-3 {
			border-left-color: var(--color_2);
			background-image: url(../img/bonuses-1.svg);
		}
		&.bonus-4 {
			border-left-color: #8143f4;
			background-image: url(../img/bonuses-2.svg);
		}
		&.bonus-5 {
			border-left-color: #ffda44;
			background-image: url(../img/bonuses-2.svg);
		}
		&.bonus-not {
			box-shadow: none;
			background-color: #fff;
			border-left-color: var(--color_9);
			.text {
				color: var(--color_9);
			}
		}
	}
}
