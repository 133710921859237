.section-comments {
	margin: 1.5rem 0;
	padding: 1rem 0.625rem;
	background-color: var(--color_0);
}
.rewiews-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	@media (max-width: 414px) {
		flex-wrap: wrap;
		gap: 1rem;
	}
	.button {
		min-width: 11.25rem;
		height: 2.125rem;
	}
}
.comments-list {
	margin-bottom: 1rem;
	.comment {
		padding: 2rem 1rem;
		border-bottom: 1px solid #f1f1f1;
		@media (max-width: 680px) {
			padding: 1rem 0;
		}
	}
	.title {
		color: var(--color_7);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 0.375rem;
	}
	.text {
		margin-bottom: 1rem;
		color: var(--color_7);
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 128.571%;
		letter-spacing: 0.07px;
		p {
			margin: 0;
		}
		.rev {
			display: none;
		}
	}

	.comment-reply-link,
	.comment-reply-login {
		margin-top: 1rem;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 128.571%;
		letter-spacing: 0.07px;
		margin: 1rem 0;
		color: var(--color_1);
	}
}
.no-comments {
	padding: 0 1rem;
}
.comment-author {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
	.author {
		display: flex;
		align-items: center;
		gap: 0.75rem;
	}
	.avatar {
		min-width: 3rem;
		width: 3rem;
		height: 3rem;
		border-radius: 100%;
		border: 1px solid var(--color_2);
		overflow: hidden;
	}
	.name {
		color: var(--color_7);
		font-size: 1rem;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 0.375rem;
	}
	.date {
		color: var(--color_9);
		font-size: 0.75rem;
		font-weight: 500;
		text-transform: uppercase;
	}
}
.comment-rating {
	text-align: center;
	.smile {
		width: 1.875rem;
		height: 1.875rem;
		border-radius: 100%;
		background-size: cover;
		margin: 0 auto 0.25rem;
		&.red {
			background-image: url(../img/smile-red.svg);
		}
		&.yellow {
			background-image: url(../img/smile-yellow.svg);
		}
		&.green {
			background-image: url(../img/smile-green.svg);
		}
	}
}
.comment-awaiting-moderation {
	color: var(--color_2);
	margin-bottom: 1rem;
	font-size: 0.875rem;
}

.comment-respond {
	padding: 1rem;
	@media (max-width: 680px) {
		padding: 1rem 0 0;
	}
	.logged-in-as {
		display: none;
	}
	.must-log-in {
		//display: none;
		a {
			color: var(--color_1);
		}
		.required-field-message {
			display: block;
		}
	}
	p {
		color: var(--color_5);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 128.571%;
		letter-spacing: 0.07px;
		margin: 1rem 0;
	}

	.comment-reply-title {
		color: var(--color_5);
		font-size: 1rem;
		font-weight: 700;
		line-height: 125%;
		letter-spacing: 0.08px;
		text-transform: uppercase;
		#cancel-comment-reply-link {
			text-transform: none;
			margin-top: 1rem;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 128.571%;
			letter-spacing: 0.07px;
			margin: 1rem 0;
			color: var(--color_1);
			display: block;
		}
	}

	input#email,
	input#author {
		width: 100%;
		max-width: 32.5rem;
		padding: 0.625rem;
		height: 2.75rem;
		border-radius: 0.1875rem;
		background: #f1f1f1;
		border: 1px solid #f1f1f1;
		font-size: 1rem;
		line-height: 1;
	}
	textarea {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		padding: 1rem 1rem 1rem 2rem;

		border-radius: 0.1875rem;
		background: #f1f1f1;
		border: 1px solid #f1f1f1;
		font-size: 1rem;
		line-height: 1;
		&#comment {
			height: 12.5rem;
			min-height: 2.75rem;
			border-left: 2px solid var(--color_1);
			background-image: url(../img/icons/chat-blue.svg);
			background-repeat: no-repeat;
			background-position: 0.5rem 0.85rem;
			background-size: 20px auto;
		}
		&#positive {
			min-height: 2.75rem;
			border-left: 2px solid #1bae72;
			background-image: url(../img/icons/pros.svg);
			background-repeat: no-repeat;
			background-position: 0.5rem 0.85rem;
		}
		&#negative {
			min-height: 2.75rem;
			border-left: 2px solid #f64747;
			background-image: url(../img/icons/cons.svg);
			background-repeat: no-repeat;
			background-position: 0.5rem 1rem;
		}
	}

	.validation-error {
		display: block;
		color: red;
		font-size: 0.75rem;
	}
	.comment-form-cookies-consent {
		display: none;
	}
	.form-submit {
		input {
			min-width: 11.25rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			box-sizing: content-box;
			border: 1px solid transparent;
			box-sizing: border-box;
			font-size: 0.875rem;
			padding: 0.5rem 1rem;
			font-size: 0.75rem;
			font-weight: 700;
			line-height: 100%; /* 0.5rem */
			text-transform: uppercase;
			min-height: 2.125rem;
			border-radius: 0.1875rem;
			background: var(--color_1);
			box-shadow: 0 0.625rem 1.875rem 0 rgba(0, 0, 0, 0.08);
			color: var(--color_0);
			cursor: pointer;
			&:hover {
				color: var(--color_0);
				background: var(--color_2);
				border-color: var(--color_2);
			}
			&:active,
			&:focus {
				color: var(--color_0);
				background: var(--color_2);
				border-color: var(--color_2);
				box-shadow: none;
			}
			&:disabled,
			&.disabled {
				color: var(--color_0);
				background: var(--color_1);
				opacity: 0.1;
				cursor: not-allowed;
			}
		}
	}
}

.comments-rating {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	@media (max-width: 680px) {
		display: block;
		margin-top: 1rem;
	}
	.title {
		color: var(--color_5);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
	}
	.rait-stars {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 15rem;
	}
	.star {
		margin: 0 0.125rem;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;

		input {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}
		span {
			display: inline-flex;
			align-items: center;

			&::before {
				content: "";
				display: inline-block;
				width: 1.25rem;
				height: 1.25rem;
				-ms-flex-negative: 0;
				flex-shrink: 0;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				background-image: url(../img/icons/star-off.svg);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		input:checked + span::before {
			background-image: url(../img/icons/star-active.svg);
		}
	}
	.star.active > span::before {
		background-image: url(../img/icons/star-active.svg);
	}
}
.comments-list {
	.comment-respond {
		.comments-rating,
		.comment-form-positive,
		.comment-form-negative {
			display: none;
		}
	}
}

.comment-form{
	display: flex;
	flex-direction: column;
	.comment-notes{
		order: 1;
		margin-bottom: 0;
	}
	.comment-form-author{
		order: 2;
		margin-bottom: 0;
	}
	.comment-form-email{
		order: 3;
		margin-bottom: 0;
	}
	.comments-rating{
		order: 4;
		margin-bottom: 0;
		margin-top: 1rem;
	}
	.comment-form-positive{
		order: 5;
		margin-bottom: 0;
	}
	.comment-form-negative{
		order: 6;
		margin-bottom: 0;
	}
	.comment-form-comment{
		order: 7;
		margin-bottom: 0;
	}
	.comment-form-cookies-consent{
		order: 8;
		margin-bottom: 0;
	}
	.form-submit{
		order: 9;
		margin-bottom: 0;
	}
}