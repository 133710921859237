.popular-news {
	padding: 1rem 0;
	//margin: 1.5rem 0;
	@media (max-width: 992px) {
		width: 50%;
		padding: 0 0.625rem;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
	.widget-title {
		text-transform: uppercase;
	}
	.tabs {
		.tabs-list {
			display: flex;
			list-style-type: none;
			gap: 0.625rem;
			padding: 0;
			border-bottom: 1px solid var(--color_5);
			li {
				color: var(--color_5);
				font-size: 0.875rem;
				font-weight: 700;
				line-height: 1.125rem;
				cursor: pointer;
				border-bottom: 1px solid transparent;
				padding: 0.5rem;
			}
			.active {
				position: relative;
				&::after {
					content: "";
					width: 100%;
					height: 0.25rem;
					left: 0;
					bottom: -0.1875rem;
					background-color: var(--color_2);
					position: absolute;
					z-index: 10;
				}
			}
		}
	}
}
