.post {
	.image {
		display: block;
		aspect-ratio: 4 / 2.5;
		object-fit: cover;
		border-radius: 0.625rem;
		background-size: cover;
		background-position: 50% 50%;
		margin-bottom: 0.5rem;
		border: 1px solid #d9d9d9;
		@media (max-width: 414px) {
			height: 13rem;
		}
	}
	.title {
		color: var(--color_5);
		font-size: 1rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1.25;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		margin-bottom: 0.5rem;
		&:hover {
			color: var(--color_1);
		}
	}
	.text {
		color: var(--color_7);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 160.768%;
		margin-bottom: 0.5rem;
		overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
	}
	.subtitle-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		.tag {
			font-weight: 400;
			font-size: 0.75rem;
			color: var(--color_11);
		}
		.date {
			font-size: 0.75rem;
		}
	}
}

.post-big {
	width: 100%;
	height: 23.125rem;
	border-radius: 1.125rem;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 1.5rem;
	position: relative;
	padding: 1.5rem 3rem;
	display: flex;
	align-items: flex-end;
	@media (max-width: 480px) {
		height: 20rem;
		padding: 1rem;
		border-radius: 0.625rem;
	}
	@media (max-width: 360px) {
		height: 16rem;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 1.125rem;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
		z-index: 1;
		@media (max-width: 480px) {
			border-radius: 0.625rem;
		}
	}
	.title {
		position: relative;
		z-index: 10;
		color: var(--color_0);
		font-size: 2.25rem;
		font-style: normal;
		font-weight: 700;
		line-height: 138.889%;
		letter-spacing: 0.018px;
		text-transform: uppercase;
		@media (max-width: 480px) {
			font-size: 1.2rem;
		}
	}
	&:hover {
		.title {
			opacity: 0.7;
		}
	}
}

.post-popular {
	display: grid;
	grid-template: 1fr 1fr / 1fr 5rem;
	column-gap: 0.5rem;
	padding: 1rem 0;
	border-bottom: 1px solid #f1f1f1;
	// grid-template-columns: 1fr;
	.title {
		a {
			color: var(--color_5);
			font-size: 1rem;
			font-weight: 700;
			line-height: 128.571%;
			//text-transform: uppercase;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			&:hover {
				color: var(--color_1);
			}
		}
	}
	.subtitle-box {
		.tag {
			color: var(--color_11);
			font-size: 0.75rem;
			font-weight: 400;
			text-transform: uppercase;
		}
		.date {
			color: var(--color_5);
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: uppercase;
		}
	}
	.image {
		grid-column: 2 / 3;
		grid-row: 1 / 3;
		border-radius: 0.625rem;
		background-size: cover;
		background-position: 50% 50%;
		border: 1px solid #d9d9d9;
	}
}

.post-hot {
	margin-bottom: 0.5rem;
	.image {
		width: 100%;
		aspect-ratio: 4/3.5;
		object-fit: cover;
		position: relative;
		border: 1px solid #d9d9d9;
	}
	.stiker {
		min-width: 6.25rem;
		height: 1.875rem;
		padding: 0 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--color_0);
		font-size: 0.625rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		border-radius: 0.375rem 0.375rem 0 0.375rem;
		background: #e42a48;
		box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.12);
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
	}
	.title {
		text-transform: uppercase;
		margin-bottom: 0.5rem;
	}
	.text {
		color: var(--color_7);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 160.768%;
		margin-bottom: 0.5rem;
	}
	.subtitle-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		.tag {
			font-weight: 400;
			font-size: 0.75rem;
		}
		.date {
			font-size: 0.75rem;
		}
	}
}
