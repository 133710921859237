.cover {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
		background: linear-gradient(90deg, #010101 0%, rgba(1, 1, 1, 0) 56.85%);
	}
	img {
		width: 100%;
		aspect-ratio: 5/3;
		object-fit: cover;
		position: relative;
		z-index: 1;
	}
	.box {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
		margin: auto 0;
		padding: 2.5rem;
		color: var(--color_0);
		font-size: 3rem;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		text-transform: uppercase;
		@media (max-width: 480px) {
			font-size: 1.5rem;
			padding: 1.5rem;
		}
	}
}
