.preview-casino {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem 2rem;
	.item {
		width: calc(50% - 1rem);
		border: 2px solid #eaf5fb;
		border-radius: 0.75rem;
		padding: 1.25rem;
		@media (max-width: 640px) {
			width: 100%;
		}
		.title-big {
			font-size: 1rem;
			font-family: "Inter-Bold";
			text-transform: uppercase;
			margin-bottom: 0.75rem;
			color: var(--color_4);
			span {
				color: var(--color_9);
			}
		}

		.title {
			font-size: 1rem;
			font-family: "Inter-Bold";
			display: flex;
			align-items: center;
			gap: 0.5rem;
			margin-bottom: 0.75rem;
			&::before {
				content: "";
				width: 1.875rem;
				height: 1.875rem;
				margin-left: -0.5rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}
		&.two-item,
		&.there-item,
		&.for-item,
		&.five-item {
			background: var(--color_0);
		}
		&.one-item {
			border: none;
			display: flex;
			gap: 1.875rem;
			padding: 0;
			@media (max-width: 480px) {
				gap: 1rem;
			}
			@media (max-width: 375px) {
				flex-direction: column;
			}
			.col-1 {
				width: 9.375rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				gap: 0.625rem;
				@media (max-width: 375px) {
					width: 100%;
					justify-content: center;
				}
				.casino-logo {
					width: 9rem;
					img {
						width: 100%;
						aspect-ratio: 1/1;
						object-fit: cover;
						border-radius: 0.625rem;
						box-shadow: 0 1rem 1.4rem 0 rgba(0, 0, 0, 0.05);
					}
				}
				.casino-rating {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.35rem;
					font-size: 0.875rem;
					line-height: 1;
					span {
						font-family: "Inter-Bold";
						font-size: 0.875rem;
						line-height: 1;
					}
					&:before {
						content: "";
						display: block;
						width: 1.125rem;
						height: 1.125rem;

						background-size: contain;
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-image: url(../img/icons/star.svg);
					}
				}
			}
			.col-2 {
				width: calc(100% - 11.25rem);
				display: flex;
				flex-direction: column;
				gap: 0.625rem;
				@media (max-width: 375px) {
					width: 100%;
				}
				.info-bonus {
					display: flex;
					flex-direction: column;
					gap: 0.75rem;
					border: 2px solid #eaf5fb;
					background: var(--color_0);
					border-radius: 0.75rem;
					padding: 0.65rem;
					.title-bonus {
						display: inline-flex;
						align-items: flex-end;
						gap: 0.5rem;
						font-family: "Inter-Bold";
						text-transform: uppercase;
						color: var(--color_12);
						font-size: 1.125rem;
						line-height: 1;
					}
					.text {
						font-family: "Inter-Bold";
						font-size: 1.125rem;
						color: var(--color_3);
						@media (max-width: 640px) {
							font-size: 1.12rem;
						}
						@media (max-width: 480px) {
							font-size: 1rem;
						}
					}
				}
			}
		}
		&.there-item {
			.title {
				&::before {
					background-image: url(../img/icons/chery.png);
				}
			}
		}
		&.for-item {
			.title {
				&::before {
					background-image: url(../img/icons/pay.png);
				}
			}
		}
		&.five-item {
			width: 100%;
			@media (max-width: 540px) {
				padding: 0.75rem 0.65rem;
			}
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		gap: 0.625rem;
		margin: 0 !important;
		padding: 0 !important;
		list-style: none;
		li {
			display: flex;
			align-items: flex-start;
			gap: 0.5rem;
			padding-left: 0;
			margin: 0;
			position: relative;
			&:before {
				display: none;
			}
			span {
				font-size: 14px;
				&:nth-child(1) {
					width: 12rem;
					color: #b3b3b3;
				}
				&:nth-child(2) {
					display: flex;
					width: calc(100% - 7.625rem);
					color: #3e3e3e;
					em{
						font-style: normal;
					}
				}
			}
		}
	}

	.info-payment {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			width: auto;
			min-height: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;

			gap: 0.75rem;
			padding: 0;
			margin: 0;
			position: relative;
			box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.05);
			font-size: 0.75rem;
			color: #3e3e3e;
			span {
				display: inline-flex;
				padding: 0.5rem 1rem;
			}
			img{
				width: 4.25rem;
			}
			&:before {
				display: none;
			}
		}
	}

	.info-pros {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			margin: 0;
			padding: 0;
			&:before {
				content: "";
				min-width: 1.5rem;
				width: 1.5rem;
				height: 1.5rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-image: url(../img/icons/pros-circle.svg);
				position: relative;
				top: 0;
				border-radius: 0;
			}
		}
	}

	.info-bonuses {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 0.625rem;

		li {
			padding: 0;
			margin: 0;
			&:before {
				display: none;
			}
			a {
				display: flex;
				border: 2px solid var(--color_3);
				font-family: "Inter-Bold";
				text-transform: uppercase;
				font-size: 0.875rem;
				line-height: 1;
				color: var(--color_3);
				border-radius: 0.25rem;
				padding: 0.25rem 1rem;
				&:hover {
					color: var(--color_10);
					border-color: var(--color_10);
				}
				@media (max-width: 540px) {
					padding: 0.25rem 0.75rem;
					font-size: 0.75rem;
				}
			}
		}
	}
}

.ratings-list {	
	@media (max-width: 640px) {
		.preview-casino {
			.two-item,
			.there-item,
			.for-item {
				display: none;
			}
		}
	}
}

.preview-group {
	background: var(--color_3);
	padding: 0.25rem;
	border-radius: 0.625rem;
	box-shadow: 0 0.25rem 1rem 0 rgba(147, 149, 204, 0.15);
	.group-head {
		color: var(--color_0);
		font-family: "Inter-Bold";
		font-size: 1.5rem;
		line-height: 1;
		padding: 0.5rem;
		display: flex;
		gap: 1rem;
		@media (max-width: 640px) {
			font-size: 1.2rem;
		}
	}
	.preview-casino {
		background: var(--color_0);
		border-radius: 0 0 0.625rem 0.625rem;
		padding: 0.625rem;
	}
	&.preview-group-1 {
		background: #ffcb52;
	}
	&.preview-group-2 {
		background: #a3a3a5;
	}
	&.preview-group-3 {
		background: #f2994a;
	}
}
