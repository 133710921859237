.product-carousel,
.product-carousel-home,
.casino-carousel,
.post-grid-carousel,
.game-grid-carousel,
.casino-grid-carousel,
.providers-grid-carousel,
.payments-grid-carousel,
.types-games-grid-carousel,
.licensing-grid-carousel {
	margin: 1.875rem -0.625rem 1.875rem;
	position: relative;

	.swiper-button-next,
	.swiper-button-prev {
		@media (max-width: 992px) {
			display: none;
		}
		.swiper-button-disabled {
			opacity: 0;
			cursor: auto;
			pointer-events: none;
		}
	}
	.swiper {
		.post,
		.game-item,
		.provider-item,
		.post-casino,
		.payment-item,
		.types-item,
		.licensing-item {
			width: 100%;
		}
		.product-item {
			margin-bottom: 1.875rem;
			&:hover {
				box-shadow: 0 0.4375rem 1.25rem rgba(212, 215, 231, 0.5);
			}
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: 50%;
	width: 3.375rem;
	height: 3.375rem;
	margin-top: -1.5625rem;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color_2);
	background: var(--color_0);
	border-radius: 0.75rem;
	border: none;
	font-size: 0.75rem;
	@media (max-width: 640px) {
		width: 2.5rem;
		height: 2.5rem;
		font-size: 0.6rem;
	}
	&:after {
		font-size: 1.125rem;
		@media (max-width: 640px) {
			font-size: 0.8rem;
		}
	}
	&:hover {
		background: var(--color_2);
		color: var(--color_0);
	}
}
.swiper-button-next {
	right: -1.5rem;
	@media (max-width: 81.25rem) {
		right: -3rem;
	}
	@media (max-width: 1140px) {
		right: -1.5rem;
	}
	@media (max-width: 414px) {
		right: -0.5rem;
	}
}
.swiper-button-prev {
	left: -1.5rem;
	@media (max-width: 81.25rem) {
		left: -3rem;
	}
	@media (max-width: 1140px) {
		left: -1.5rem;
	}
	@media (max-width: 414px) {
		left: -0.5rem;
	}
}
.swiper-button-disabled {
	opacity: 0;
	cursor: auto;
	pointer-events: none;
}
.swiper-pagination {
	display: none;
	@media (max-width: 992px) {
		display: block;
		bottom: -2rem !important;
	}
}
.swiper-pagination-bullet-active {
	background: #2c3345;
}
