.views {
	display: inline-flex;
	gap: 1rem;
	@media (max-width: 992px) {
		display: none;
	}
	.but {
		width: 1.25rem;
		height: 1.25rem;
		color: var(--color_5);
		cursor: pointer;
		&.active,
		&:hover {
			color: var(--color_2);
		}
	}
}
.toggle-filter {
	text-transform: none;
	gap: 0.5rem;
	min-width: 10.3125rem;
	height: 2rem;
	padding: 0.375rem 1rem 0.25rem;
	display: none;
	@media (max-width: 992px) {
		display: inline-flex;
		align-items: center;
		line-height: 1;
	}
	@media (max-width: 414px) {
		min-width: calc(50% - 0.5rem);
	}
	img,
	svg {
		width: 1rem;
		margin-top: -0.1875rem;
	}
}
.sort-by {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 1rem;
	gap: 1rem;
}
.post-count {
	color: var(--color_5);
	font-size: 1.25rem;
	line-height: 1;
	font-style: normal;
	font-weight: 700;
	margin-right: auto;
	@media (max-width: 480px) {
		width: 100%;
		font-size: 1rem;
	}
}
.sorts {
	position: relative;
	@media (max-width: 414px) {
		min-width: calc(50% - 0.5rem);
	}
	.dropnav {
		min-width: 10.3125rem;
		top: 100%;
		right: 0;
		display: none;
		position: absolute;
		z-index: 500;
		@media (max-width: 414px) {
			width: 100%;
		}
		ul {
			border-radius: 3;
			background: #e8e8e8;
			box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.08);
			padding: 0;
			margin: 0;
			list-style: none;
			li {
				width: 100%;
				border-bottom: 1px solid #eee;
				padding: 0.5rem 1rem;
				&:last-child {
					border: none;
				}
			}
		}
		&.active {
			display: block;
		}
	}

	.check > input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	.check > span {
		user-select: none;
		display: inline-flex;
		align-items: center;
		gap: 0.25rem;
		font-size: 0.75rem;
		color: var(--color_4);
		cursor: pointer;

		&:after {
			content: "";
			width: 1.25rem;
			height: 1.25rem;
			margin-left: 0.25rem;
		}
	}

	.check > input:not(:disabled):not(:checked) + span:hover {
		color: var(--color_1);
	}

	.check > input:checked + span {
		color: var(--color_1);
		&:after {
			content: "";
			background-image: url(../img/icons/check.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
.select-sort {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	min-width: 10.3125rem;
	height: 2rem;
	padding: 0.375rem 1rem 0.25rem;
	border-radius: 0.1875rem;
	font-size: 0.75rem;
	line-height: 1;
	border: 1px solid var(--color_1);
	background: #e8e8e8;
	box-shadow: 0 0.625rem 1.875rem 0 rgba(0, 0, 0, 0.08);
	position: relative;
	cursor: pointer;

	&:before {
		content: "";
		width: 1rem;
		height: 1rem;
		background-image: url(../img/icons/sort.svg);
		background-size: contain;
		margin-top: -0.1875rem;
		display: block;
	}
	&:after {
		content: "";
		width: 0.75rem;
		height: 0.75rem;
		background-image: url(../img/icons/arrow-right.svg);
		background-size: contain;
		transform: rotate(90deg);
		margin-top: -0.1875rem;
		opacity: 0.8;
		display: block;
		transition: 0.3s;
		margin-left: auto;
	}
	&.active {
		&:after {
			content: "";
			transform: rotate(-90deg);
		}
	}
}

.params {
	position: relative;
	margin-bottom: 1.5rem;
	color: var(--color_5);
	&.loads {
		opacity: 0.5;
	}
	.title {
		color: var(--color_5);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 16.8px */
		text-transform: uppercase;
		margin-bottom: 0.5rem;
	}
	.dropnav {
		top: 100%;
		right: 0;
		left: 0;
		display: none;
		position: absolute;
		z-index: 500;
		ul {
			border-radius: 3;
			background: var(--color_0);
			box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.08);
			padding: 0;
			margin: 0;
			list-style: none;
			overflow-y: auto;
			li {
				width: 100%;
				border-bottom: 1px solid #eee;
				padding: 0.75rem 1rem;
				&:last-child {
					border: none;
				}
			}
		}
		&.active {
			display: block;
		}
	}
	.check {
		display: flex;
		align-items: center;
	}
	.check > input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	.check > span {
		user-select: none;
		display: inline-flex;
		align-items: center;
		gap: 0.5rem;
		font-size: 0.875rem;
		line-height: 1;
		font-weight: 400;
		color: var(--color_4) 614;
		cursor: pointer;

		img,
		svg {
			width: 1rem;
			height: auto;
		}
	}

	.check > input:not(:disabled):not(:checked) + span:hover {
		color: var(--color_1);
	}

	.check > input:checked + span {
		color: var(--color_1);
		&:after {
			content: "";
			background-image: url(../img/icons/check.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
.select-param {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	min-width: 10.3125rem;
	height: 2.875rem;
	padding: 0.375rem 1rem 0.25rem;
	border-radius: 0.1875rem;
	font-size: 0.875rem;
	font-weight: normal;
	line-height: 1;
	border: 1px solid var(--color_1);
	position: relative;
	cursor: pointer;
	.param {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		img,
		svg {
			width: 1rem;
			height: auto;
		}
	}
	&:after {
		content: "";
		width: 0.75rem;
		height: 0.75rem;
		background-image: url(../img/icons/arrow-right.svg);
		background-size: contain;
		transform: rotate(90deg);
		margin-top: -0.1875rem;
		opacity: 0.8;
		display: block;
		transition: 0.3s;
		margin-left: auto;
	}
	&.active {
		&:after {
			content: "";
			transform: rotate(-90deg);
		}
	}
}
