.widget-top-casinos {
	width: 100%;
	@media (max-width: 992px) {
		width: 50%;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
	.top-casinos {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.button {
		width: 13.75rem;
		margin: 0 auto;
		display: flex;
	}

	.post-casino-box {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;
		width: 100%;
		.image {
			width: 3.625rem;
			height: 3.625rem;
			border-radius: 0.5rem;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			border: 1px solid #d9d9d9;
		}
		.title-box {
			width: calc(100% - 5.375rem);
			.title {
				color: var(--color_12);
				font-size: 0.875rem;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 0.25rem;
			}
			.text {
				color: var(--color_5);
				font-size: 0.875rem;
				line-height: 1.2;
				font-weight: 600;
				margin: 0;
			}
		}
		.arrow {
			min-width: 1.25rem;
			width: 1.25rem;
			height: 1.25rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: var(--color_8);
			margin-left: auto;
		}
		&:hover {
			.arrow {
				color: #000;
			}
		}
	}
}
