
	.presets-item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 1rem 1.5rem;
		border-radius: 0.5rem;
		border: 1px solid #d9d9d9;
		background: #fff;
		&:hover {
			color: var(--color_3);
			border-color: var(--color_3);
		}
	}

