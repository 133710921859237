.collapsing-text {
	overflow: hidden;
	.entry-text {
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.more-link {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 1.5rem;
		gap: 0.5rem;
		color: var(--color_2);
		font-size: 0.875rem;
		text-transform: uppercase;
		font-weight: 700;
		height: 3rem;
		background: rgba(255, 255, 255, 0);
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(250, 250, 250) 36%, rgb(250, 250, 250) 100%);
		background: -webkit-gradient(
			left top,
			left bottom,
			color-stop(0%, rgba(255, 255, 255, 0)),
			color-stop(36%, rgb(250, 250, 250)),
			color-stop(100%, rgb(250, 250, 250))
		);
		background: -webkit-linear-gradient(
			top,
			rgba(255, 255, 255, 0) 0%,
			rgb(250, 250, 250) 36%,
			rgb(250, 250, 250) 100%
		);
		background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(250, 250, 250) 36%, rgb(250, 250, 250) 100%);
		background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(250, 250, 250) 36%, rgb(250, 250, 250) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(250, 250, 250) 36%, rgb(250, 250, 250) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFF", endColorstr="#FFFFFF", GradientType=0);
		position: relative;
		z-index: 10;
		&:after {
			content: "↓";
			width: 1rem;
			display: block;
		}
		.open {
			&:after {
				transform: rotate(-180deg);
			}
		}
	}
}
