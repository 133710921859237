.types-item {
	width: calc(20% - 0.85rem);
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	//gap: 1rem;
	//border: 1px solid #d9d9d9;
	background-color: #d9d9d9;
	overflow: hidden;
	background-blend-mode: multiply;
	.image {
		border-radius: 0.625rem 0.625rem 0 0;	
		background-color: #d9d9d9;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		width: 100%;
		aspect-ratio: 6/4;
		object-fit: cover;
		background-blend-mode: multiply;
	}
	.caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 1rem;
		padding: 0 1rem 1rem;
		.title {
			color: var(--color_7);
			font-size: 1rem;
			font-weight: 700;
			text-transform: uppercase;
		}
		.count {
			color: var(--color_11);
			font-size: 0.875rem;
			font-weight: 400;
		}
	}
}
