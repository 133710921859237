.section-news {
	margin-bottom: 1.5rem;
	.text {
		color: var(--color_5);
	}
	.post-grid .post {
		@media (max-width: 680px) {
			width: calc(50% - 0.9375rem);
		}
		@media (max-width: 414px) {
			width: 100%;
		}
	}
	.post-grid .post.post-hot {
		@media (max-width: 992px) {
			width: calc(33.33% - 0.9375rem);
		}
		@media (max-width: 680px) {
			width: calc(50% - 0.9375rem);
		}
		@media (max-width: 414px) {
			width: 100%;
		}
	}

	.news-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0.5rem 0;
		.left-block {
			width: calc(33% - 0.75rem);
			@media (max-width: 680px) {
				width: 100%;
			}
			.widget-title {
				text-transform: uppercase;
				a {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 0.75rem;
					@media (max-width: 680px) {
						justify-content: flex-start;
					}
					img,
					svg {
						min-width: 1.125rem;
						width: 1.125rem;
						height: auto;
					}
					&:hover {
						color: var(--color_2);
					}
				}
			}
		}
		.post-grid {
			width: calc(67% - 0.75rem);
			margin: 0;
			@media (max-width: 680px) {
				width: 100%;
			}
			.post {
				width: calc(50% - 0.75rem);
				@media (max-width: 540px) {
					width: calc(50.33% - 0.9375rem);
				}
				@media (max-width: 414px) {
					width: 100%;
				}
			}
		}
	}
}

.blog-banner {
	margin: 1.5rem 0;
	@media (max-width: 992px) {
		margin: 0 0 1rem;
	}
}
