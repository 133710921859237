.top {
	width: 100%;
	min-height: 34.375rem;
	padding: 3.75rem 0;
	display: flex;
	align-items: center;

	margin-bottom: 0;
	background-size: cover;
	background-position: 50% 0;
	@media (max-width: 1140px) {
		min-height: 30rem;
	}
	@media (max-width: 992px) {
		min-height: 20rem;
	}
	@media (max-width: 480px) {
		min-height: 14rem;
		background-position: 20% 0;
	}
	.top-title {
		max-width: 37.5rem;
		font-size: 2.625rem;
		font-style: normal;
		font-weight: 700;
		line-height: 119.048%;
		letter-spacing: 0.021px;
		text-transform: uppercase;
		margin-bottom: 2rem;
		color: #fff;
		@media (max-width: 992px) {
			font-size: 2rem;
			margin-bottom: 1.5rem;
		}
	}
}
.top-home {
	min-height: 46rem;
	margin-top: -4rem;

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	text-align: center;
	padding: 4rem 0 0;
	z-index: 20;
	@media (max-width: 992px) {
		margin-top: 2rem;
		padding-top: 4rem;
	}
	.container {
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		gap: 3rem;
	}
	&:after {
		content: "";
		width: 100%;
		height: calc(100% - 6.25rem);
		background-color: var(--color_2);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
	@media (max-width: 992px) {
		padding-bottom: 2rem;
	}
	@media (max-width: 992px) {
		padding-bottom: 0;
	}
	.top-title {
		max-width: 43.75rem;
		font-family: "Inter-Bold";
		font-size: 3rem;
		margin: 0;
		@media (max-width: 640px) {
			font-size: 2.5rem;
		}
		@media (max-width: 480px) {
			font-size: 2rem;
		}
	}
	.text {
		max-width: 50rem;
		margin: -3rem auto 0.5rem;
		color: #fff;
	}
}
