@font-face {
	font-family: "Inter-Bold";
	font-display: swap;
	src:
		url("../fonts/Inter-Bold.woff2") format("woff2"),
		url("../fonts/Inter-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Inter-Regular";
	font-display: swap;
	src:
		url("../fonts/Inter-Regular.woff2") format("woff2"),
		url("../fonts/Inter-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	
}
@font-face {
	font-family: "Inter-SemiBold";
	font-display: swap;
	src:
		url("../fonts/Inter-SemiBold.woff2") format("woff2"),
		url("../fonts/Inter-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: "Inter-Regular";
	font-display: swap;
	src:
		url("../fonts/Inter-Regular.woff2") format("woff2"),
		url("../fonts/Inter-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
